import React from 'react';
import {
  Container,
  TopSection,
  TopBar,
  Logo,
  Heading,
  Tagline,
  TopSectionContent,
  MidSection,
  Features,
  Feature,
  FeatureImg,
  FeatureDescription,
  FeatureHeading,
  BottomSection,
  BottomSectionContent,
  BottomSectionImg,
  BottomSectionTextContainer,
  BottomSectionHeading,
  BottomSectionDescription,
} from './components';

declare global {
  interface Window {
    dataLayer: any;
    snaptr: any;
    fbq: any;
  }
}

export const Home = () => {
  return (
    <Container>
      <TopBar>
        <Logo />
      </TopBar>
      <TopSection>
        <TopSectionContent>
          <Heading>Förbättra din ekonomi</Heading>
          <Tagline>
            Framtidens ekonomiapp är snart här. Glöm kvitton, missade fakturor
            och dyra avgifter. Få bättre koll och låt pengarna växa med smarta
            funktioner som hjälper dig att spara, betala, budgetera och hantera
            dina transaktioner, i en enda app.
            <br />
            <br />
            Innan vi lanserar vill vi att du är med och formar framtiden för
            dina pengar. Utforska beta-appen och dela med dig av dina tankar.
          </Tagline>
          <div data-vl-widget="popupTrigger" />
        </TopSectionContent>
      </TopSection>
      <MidSection>
        <Features>
          <Feature>
            <FeatureImg src="https://res.cloudinary.com/vrlps/image/upload/v1602255435/8RKRT9qyV56rPycDJ5qSEMXwUUA.png" />
            <FeatureHeading>Låt dina pengar göra jobbet</FeatureHeading>
            <FeatureDescription>
              Få ränta på pengarna på ditt kort, från första kronan.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureImg src="https://res.cloudinary.com/vrlps/image/upload/v1602255446/MfW3A0lztZzGWGr1i-RLl0NHICY.png" />
            <FeatureHeading>
              Förenkla din ekonomi, låt dina pengar växa
            </FeatureHeading>
            <FeatureDescription>
              Koppla alla dina konton och styr ekonomin från en enda app.
            </FeatureDescription>
          </Feature>
          <Feature>
            <FeatureImg src="https://res.cloudinary.com/vrlps/image/upload/v1602255454/2PehPc0x_qq7I15glEkwd1Xp6K4.png" />
            <FeatureHeading>
              En transaktion är mer än ett kontoutdrag
            </FeatureHeading>
            <FeatureDescription>
              Betala nu, senare eller splitta med dina vänner. Använd kortet,
              mobilen eller en smartwatch för att betala.
            </FeatureDescription>
          </Feature>
        </Features>
      </MidSection>
      <BottomSection>
        <BottomSectionContent>
          <BottomSectionImg />
          <BottomSectionTextContainer>
            <BottomSectionHeading>Om Rocker</BottomSectionHeading>
            <BottomSectionDescription>
              Vi är ett svenskt betalningsinstitut som delvis ägs av
              medieföretaget Schibstedt. Vår vision är att utveckla smarta och
              lättanvända lösningar som ska revolutionera din vardagsekonomi.
              Utan dyra avgifter, svåra ord och bankgubbar i kostym.
            </BottomSectionDescription>
          </BottomSectionTextContainer>
        </BottomSectionContent>
      </BottomSection>
    </Container>
  );
};
