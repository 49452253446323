import styled from 'styled-components';

export const Container = styled.div`
  background-color: #3d308e;
`;
export const TopSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-height: 500px;
  height: 70vh;
  padding-top: 10vh;
  background-image: ${() =>
    `url(${process.env.PUBLIC_URL + '/img/desktop.png'})`};
  background-size: 500px;
  background-repeat: no-repeat;
  background-position: bottom right;

  @media (max-width: 1500px) {
    background-size: 400px;
  }

  @media (max-width: 1050px) {
    max-height: none;
    height: 680px;
    padding-top: 0;
    background-image: ${() =>
      `url(${process.env.PUBLIC_URL + '/img/mobile.png'})`};
    background-position-y: 95%;
    background-position-x: center;
    background-size: 300px;
  }
`;

export const TopBar = styled.div`
  display: flex;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 12px;
  justify-content: center;
`;

export const Logo = styled.img.attrs({
  src: process.env.PUBLIC_URL + '/img/rocker-logo.png',
})`
  max-height: 60px;
  width: 200px;
  align-self: flex-start;
`;

export const Heading = styled.h1`
  -webkit-font-smoothing: auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  color: #fff;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  padding-bottom: 10px;
  max-width: 500px;
  padding: 0;
  margin: 10px 0;
`;

export const Tagline = styled.p`
  -webkit-font-smoothing: auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #fff;
  font-weight: 400;
  text-decoration: none;
  line-height: normal;
  text-align: center;
  width: 90vw;
  max-width: 500px;
  margin-top: 0;
  margin-bottom: 50px;
  padding: 0;
`;

export const TopSectionContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
`;

export const MidSection = styled.div`
  background-color: #ffffff;
  padding: 40px 0;
`;
export const Features = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 0 auto;
  max-width: 1140px;
`;
export const Feature = styled.div`
  display: flex;
  flex: 0 0 30%;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  @media (max-width: 665px) {
    flex: 0 0 75%;
  }
`;

export const FeatureImg = styled.img`
  max-height: 70px;
  margin-bottom: 20px;
`;

export const FeatureDescription = styled.p`
  -webkit-font-smoothing: auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #848b96;
  font-weight: 400;
  text-align: center;
  line-height: normal;
  height: 100%;
`;

export const FeatureHeading = styled.h3`
  -webkit-font-smoothing: auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  color: #333;
  font-weight: 500;
  font-style: normal;
  text-decoration: none;
  text-align: center;
  line-height: normal;
  margin: 0;
`;
export const BottomSection = styled.div`
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  height: 400px;
`;

export const BottomSectionContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1140px;
  width: 100vw;
`;

export const BottomSectionImg = styled.img.attrs({
  src: process.env.PUBLIC_URL + '/img/phone.png',
})`
  max-width: 445px;
  height: auto;
  width: 100vw;
`;

export const BottomSectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 635px;
  width: 90vw;
`;

export const BottomSectionHeading = styled.h3`
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  color: #333;
  font-weight: 500;
  text-align: center;
  line-height: normal;
`;

export const BottomSectionDescription = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: #333;
  font-weight: 400;
  text-align: center;
  line-height: normal;
`;
